<template>
  <div class="base-form-wrapp" style="height: auto">
    <div class="detail-info__name" v-if="!isEmptyDetail" :class="{'not-active': picoStatus === 3}">
      <h2 class="title">Pico {{detailPico.alias}} (#{{detailPico.pico_id}})
        <button v-if="getRole === 1" class="delete-btn" @click="showMoreInfo = !showMoreInfo"><i class="anticon"><more-icon/></i></button>
      </h2>
      <div v-if="showMoreInfo" class="detail-info__more">
        <a href="javascript:void(0);" class="detail-info__more--delete" @click="deletePico">{{ $t('picos.delete_pico') }}</a>
      </div>
    </div>
    <a-tabs default-active-key="1">
      <a-tab-pane key="1" :tab="$t('basics.general_tab')">
        <div v-if="showMoreInfo" class="detail-info__more--overlay" @click="showMoreInfo = !showMoreInfo"></div>
        <a-form :form="form" class="base-form " :class="{'create-form': isEmptyDetail}">
          <a-row :class="{'not-active': baseStatus === 3}">
            <a-col :lg="12">
              <a-form-item :label="$t('bases.linked_organization')">
                <a-select
                    size="large"
                    :placeholder="$t('bases.no_organization')"
                    disabled
                    v-decorator="['organization_id',{rules: [
                  {required: true, message: $t('messages.requiredField')}],
                  initialValue: !isEmptyDetail && !!detailPico.organization ? detailPico.organization.id : undefined}]">
                  <a-select-option value="no_organization">
                    {{ $t('bases.no_organization') }}
                  </a-select-option>
                  <a-select-option v-for="item in organizationsList" :value="item.id" :key="item.id">
                    {{item.name}}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item :label="$t('bases.managers_full_name')">
                <a-input
                    size="large"
                    disabled
                    v-decorator="['manager', {initialValue: !isEmptyDetail && !!detailPico.organization ? detailPico.organization.manager : undefined }]">
                </a-input>
              </a-form-item>
              <a-form-item label="Email">
                <a-input
                    size="large"
                    disabled
                    v-decorator="['email', {initialValue: !isEmptyDetail && !!detailPico.organization ? detailPico.organization.email : undefined}]">
                </a-input>
              </a-form-item>
              <a-form-item :label="$t('basics.phone_number')">
                <a-input
                    size="large"
                    disabled
                    v-decorator="['phone', {initialValue: !isEmptyDetail && !!detailPico.organization ? detailPico.organization.phone : undefined}]">
                </a-input>
              </a-form-item>

                <a-form-item v-if="detailPico.id" :label="$t('picos.barcode') + ':'">
                    <img :src="detailPico.barcode_url" class="download-img-barcode" alt="qr_code_url">
                    <router-link
                        :to="{ name: 'QRcodePage',
                        query: { qrLink: detailPico.barcode_url }}"
                        target="_blank"
                        style="color: #40454E;"
                    >
                        <div class="download-btn">{{ $t('bases.download_png') }} <i class="anticon"><download-icon /></i></div>
                    </router-link>
                </a-form-item>
            </a-col>
            <a-col :lg="12">
              <a-form-item :label="$t('organizations.step_1.location')">
                <a-input
                    size="large"
                    disabled
                    v-decorator="['location', {initialValue: !isEmptyDetail && !!detailPico.organization ? detailPico.organization.location : undefined}]">
                </a-input>
              </a-form-item>
              <a-form-item :label="$t('picos.storage_base_id')">
                <a-input
                    size="large"
                    disabled
                    v-decorator="['base_id', {initialValue: !isEmptyDetail && !!detailPico.base_id ? detailPico.base_id : undefined}]">
                </a-input>
              </a-form-item>
              <a-form-item :label="$t('picos.user_id')">
                <a-input
                    size="large"
                    disabled
                    v-decorator="['user_id', {initialValue: !isEmptyDetail && !!detailPico.user_id ? detailPico.user_id : undefined}]">
                </a-input>
              </a-form-item>
              <a-form-item :label="$t('picos.pico_id')">
                <a-input
                    size="large"
                    disabled
                    v-decorator="['pico_id', {rules: [
                  {required: true, message: $t('messages.requiredField')},
                  {min: 1, message: $t('messages.minLength') + 1},
                  {max: 255, message: $t('messages.maxLength') + 255}],
                  initialValue: !isEmptyDetail ? detailPico.pico_id : ''}]">
                </a-input>
              </a-form-item>
              <a-form-item :label="$t('picos.alias')">
                <a-input
                    size="large"
                    :disabled="getRole !== 1"
                    v-decorator="['alias', {rules: [
                  {required: true, message: $t('messages.requiredField')},
                  {min: 1, message: $t('messages.minLength') + 1},
                  {max: 255, message: $t('messages.maxLength') + 255}],
                  initialValue: !isEmptyDetail ? detailPico.alias : ''}]">
                </a-input>
              </a-form-item>
              <a-button
                  v-if="getRole !== 1"
                  type="primary"
                  :loading="loginBtn"
                  :disabled="loginBtn || !detailPico.base_id"
                  @click="releasePico"
              >
                {{ $t('picos.release') }}
              </a-button>
                <a-button
                  v-if="getRole === 1"
                  type="primary"
                  @click="updatePico"
                  :loading="loginBtn"
                  :disabled="loginBtn || detailPico.status === 3">{{ $t('picos.save_pico') }}
                </a-button>
            </a-col>
          </a-row>
        </a-form>
      </a-tab-pane>
      <a-tab-pane key="2" :tab="$t('basics.history')">
        <a-table
            :locale={emptyText:emptyText}
            class="main-table"
            :columns="columns"
            rowKey="id"
            :data-source="detailPico.history"
            :scroll="{y: 'calc(100% - 40px)' }"
        >
          <template slot="date" slot-scope="text, record">
            <div v-if="record.from === null && record.to === null">--</div>
            <div v-else>{{`${fromToConvert(record.from)}`}} <br/> {{ record.to ? `${fromToConvert(record.to)}` : '-'}}</div>
          </template>
          <template slot="total_time" slot-scope="text, record">
            <div>{{totalConvert(record.total_time)}}</div>
          </template>
        </a-table>
      </a-tab-pane>
      <a-tab-pane key="3" :tab="$t('basics.tech_info')">
        <a-row :class="{'not-active': baseStatus === 3}" v-if="detailPico.tech_info">
          <a-col :lg="12">
            <p><span class="tech-title">Pico ID: </span>{{detailPico.tech_info.pico_id}} </p>
            <p><span class="tech-title">Spot: </span>{{detailPico.tech_info.spot}} </p>
            <p><span class="tech-title">AcceleroMax: </span>{{detailPico.tech_info.acceleroMax}} </p>
            <p><span class="tech-title">SOH: </span>{{detailPico.tech_info.SOH}} </p>
            <p><span class="tech-title">SOC: </span>{{detailPico.tech_info.SOC}} </p>
            <p><span class="tech-title">PowerIn: </span>{{detailPico.tech_info.powerIn}} </p>
            <p><span class="tech-title">lenLog: </span>{{detailPico.tech_info.lenLog}} </p>
            <p><span class="tech-title">Available: </span>{{detailPico.tech_info.available}} </p>
            <p><span class="tech-title">Issues: </span>{{detailPico.tech_info.issues}} </p>
            <p><span class="tech-title">Temp: </span>{{detailPico.tech_info.temp}} </p>
          </a-col>
          <a-col :lg="12">
            <p><span class="tech-title">Cell: </span>{{detailPico.tech_info.cell}} </p>
            <p><span class="tech-title">Cable: </span>{{detailPico.tech_info.cable}} </p>
            <p><span class="tech-title">Version: </span>{{detailPico.tech_info.version}} </p>
            <p><span class="tech-title">Status: </span>{{detailPico.tech_info.status}} </p>
            <p><span class="tech-title">Locker: </span>{{detailPico.tech_info.locker}} </p>
            <p><span class="tech-title">Current: </span>{{detailPico.tech_info.Current}} </p>
            <p><span class="tech-title">Voltage: </span>{{detailPico.tech_info.Voltage}} </p>
            <p><span class="tech-title">VoltageIn: </span>{{detailPico.tech_info.VoltageIn}} </p>
            <p><span class="tech-title">Balance: </span>{{detailPico.tech_info.balance}} </p>
          </a-col>
        </a-row>
        <a-row :class="{'not-active': baseStatus === 3}" v-else>
          <a-col :lg="12">
            <p>There is no technical info saved for the pico</p>
          </a-col>
        </a-row>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import moreIcon from '@/assets/icons/More.svg?inline'
import {requiredField, minLength, maxLength} from '@/utils/messages';
import {i18n} from "@/i18n";
import downloadIcon from '@/assets/icons/download.svg?inline'

export default {
  name: 'PicoForm',
  data() {
    return  {
      requiredField,
      minLength,
      maxLength,
      loginBtn: false,
      showMoreInfo: false,
      detailOrganizationData: {},
      emptyText: this.$t('picos.no_data'),
      columns: [
        {
          title: i18n.t('picos.base_id_from'),
          dataIndex: 'base_id_from',
          key: 'base_id_from'
        },
        {
          title: i18n.t('picos.base_id_to'),
          dataIndex: 'base_id_to',
          key: 'base_id_to'
        },
        {
          title: i18n.t('picos.user_id'),
          dataIndex: 'user_id',
          key: 'user_id'
        },
        {
          title: i18n.t('users.personal_info'),
          dataIndex: 'personal_info',
          key: 'personal_info'
        },
        {
          title: i18n.t('basics.using_date'),
          dataIndex: 'date',
          key: 'date',
          scopedSlots: { customRender: 'date' },
        },
        {
          title: i18n.t('basics.total_time'),
          dataIndex: 'total_time',
          key: 'total_time',
          scopedSlots: { customRender: 'total_time' },
        },
      ],
    }
  },
  components: {
    moreIcon,
    downloadIcon
  },
  beforeCreate() {
    this.form = this.$form.createForm(this);
  },
  mounted() {
    let modal = document.getElementsByClassName('modal-body')[0];
    if (modal) {
      modal.addEventListener('scroll', this.handleScroll);
    }
  },
  computed: {
    organizationsList() {
      return this.$store.getters['organizations/getOrganizations']
    },
    detailPico() {
      return this.$store.getters['detailPico/getPicoDetails']
    },
    baseStatus() {
      return this.$store.getters['detailPico/getBaseStatus']
    },
    getBaseOrganizationData() {
      return this.$store.getters['detailPico/getBaseOrganizationData']
    },
    isEmptyDetail() {
      return Object.keys(this.detailPico).length === 0
    },
    getRole() {
      return parseInt(this.$store.getters['getRole'])
    },
    picoStatus() {
      return this.detailPico.status.id
    }
  },
  methods: {
    handleScroll () {
      let openedSelects = document.getElementsByClassName('ant-select ant-select-open');
      let selectDropdowns = document.getElementsByClassName('ant-select-dropdown');

      openedSelects.forEach(element => {
        if (element) {
          element.classList.remove(...['ant-select-open', 'ant-select-focused']);
        }
      })

      selectDropdowns.forEach(element => {
        if (element) {
          element.style.display = 'none';
        }
      })
    },
    fromToConvert(time) {
      return this.$moment.unix(time).format('D MMMM YYYY, h:mm');
    },
    totalConvert(time) {
      const days = +this.$moment.utc(time*1000).format('D') - 1 !== 0 ? +this.$moment.utc(time*1000).format('D') - 1 + "d" + " " : "";
      const hours = +this.$moment.utc(time*1000).format('HH') !== 0 ? +this.$moment.utc(time*1000).format('H') + "h" + " " : "";
      const minutes = +this.$moment.utc(time*1000).format('mm') !== 0 ? +this.$moment.utc(time*1000).format('mm') + "m" + " " : "";
      const seconds = +this.$moment.utc(time*1000).format('ss') !== 0 ? +this.$moment.utc(time*1000).format('ss') + "s" : "";
      return days + hours + minutes + seconds;
      // return +this.$moment.utc(time*1000).format('D') - 1 + "d" + " " + this.$moment.utc(time*1000).format('HH[h] mm[m] ss[s]');
    },
    releasePico() {
      this.$confirm({
        centered: true,
        content: this.$t('messages.releasePico'),
        okText: this.$t('basics.yes'),
        cancelText: this.$t('basics.cancel'),
        width: 490,
        closable: true,
        onOk: () => {
          this.$store.dispatch('detailPico/ReleasePico', this.detailPico.id)
              .then(() => {
                this.$message.success(this.$t('picos.pico_deleted'))
                this.$emit('onClose')
              })
              .catch(error => {
                this.$message.error(error.response.data.error.errors);
              })
        },
        onCancel: () => this.showMoreInfo = false
      })
    },
    updatePico() {
      this.form.validateFields((err, values) => {
        const updateParams = {
          alias: values.alias
        }

        if (values.alias === this.detailPico.alias) {
            this.$emit('onClose')
        } else {
          this.$confirm({
            centered: true,
            content: this.$t('messages.ifPicoUpdate'),
            okText: this.$t('basics.yes'),
            cancelText: this.$t('basics.cancel'),
            width: 490,
            closable: true,
            onOk: () => {
              this.$store.dispatch('detailPico/UpdatePico', {
                  id: this.detailPico.id,
                  data: updateParams
              })
                .then(() => {
                  this.$message.success(this.$t('picos.picoUpdatedSuccess'))
                  this.$emit('onClose')
                })
                .catch(error => {

                    for (const [key, value] of Object.entries(error.response.data.error.errors)) {
                        this.form.setFields({
                            [key]: {
                                value: this.detailPico.alias,
                                errors: [new Error(value)],
                            },
                        });
                    }

                  // this.$message.error(error.response.data.error.errors);
                })
            },
            onCancel: () => this.showMoreInfo = false
          })
        }



        })
    },
    deletePico() {
      this.$confirm({
        centered: true,
        content: this.$t('messages.deletePicoMassages'),
        okText: this.$t('basics.yes'),
        cancelText: this.$t('basics.cancel'),
        width: 490,
        closable: true,
        onOk: () => {
          this.$store.dispatch('detailPico/DeletePico', this.detailPico.id)
              .then(() => {
                this.$message.success(this.$t('picos.pico_deleted'))
                this.$emit('onClose')
              })
              .catch(error => {
                this.$message.error(error.response.data.error.errors);
              })
        },
        onCancel: () => this.showMoreInfo = false
      })
    },
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  }
}
</script>

<style lang="less" scoped>
.download-img-barcode {
  display: block;
  margin: 5px 0;
  //  height: 74px;
  //width: 74px;
}

.tech-title {
  opacity:0.5;
}

.download-btn {
  display: inline-block;
  cursor: pointer;

  .anticon {
    vertical-align: middle;
  }
}
</style>


