<template>
  <div class="main-content">
    <div class="main-table-wrapper main-content-wrapp" :class="{'modal-opened': visibleModal}">
      <div class="main-table-block">
        <list-heading>
          <list-title>{{ $t('picos.picos_list') }}</list-title>
        </list-heading>
        <div class="admin-layout__list-filters">
          <search
              :placeholderText="searchPlaceholder"
              @select="searchInList"></search>
          <div class="admin-layout__filters">
            <table-size-changer
                :defaultPageSize="pageSize"
                @select="setPageSize"/>
            <list-filter
                :organizations="organizationsDropdownList"
                :checkboxes="filterCheckboxes"
                with-empty-organization
                @filterBy="filterList"
                filteredType="Pico"
                label="Organization"
            />
          </div>
        </div>
        <a-table
            :locale={emptyText:emptyText}
            class="main-table"
            :loading="loading"
            :columns="getRole === 1 ? columnsSuperAdmin : columnsAdmin"
            rowKey="id"
            :pagination="false"
            :data-source="picosList"
            @change="handleTableChange"
            :scroll="{y: 'calc(100% - 40px)' }"
        >
          <template slot="organization_name" slot-scope="text, record" v-if="getRole === 1">
            {{!!record.organization ? record.organization.name : '-'}}
          </template>
          <template slot="organization_email" slot-scope="text, record" v-if="getRole === 1">
            {{!!record.organization ? record.organization.email : '-'}}
          </template>
          <template slot="status" slot-scope="text, record">
            <div class="status"
                 :class="{'is-active': record.status.id === 1,
                      'is-inactive': record.status.id === 2,
                      'is-deleted': record.status.id === 3}">
                        <span v-if="record.status.id === 1">{{$t('picos.unlocked')}}</span>
                        <span v-if="record.status.id === 2">{{$t('picos.locked')}}</span>
                        <span v-if="record.status.id === 3">{{$t('picos.deleted')}}</span>
                      </div>
          </template>
          <template slot="action" slot-scope="text, record">
            <a-button
                @click="setActiveModal(true, record.id)"
                type="primary">
              {{ $t('organizations.details') }}
            </a-button>
          </template>
        </a-table>
      </div>
      <pagination
          @change="setPageNumber"
          :defaultPageNumber="pageNumber"
          :paginationData="pagination"
      />
    </div>
    <detail-pico
        @updateList="fetchData({page: pageNumber, ...filters})"
        @closeModal="setActiveModal"
        :active="visibleModal"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import { formatDate } from '@/utils/helpers/dateFormat';
import ListTitle from '@/components/ListTitle/ListTitle'
import ListHeading from '@/components/ListHeading/ListHeading'
import Search from '@/components/Search/Search'
import TableSizeChanger from '@/components/TableSizeChanger/TableSizeChanger'
import ListFilter from '@/components/ListFilter/ListFilter'
import Pagination from '@/components/Pagination/Pagination'
import { defaulVariablesPicos } from '@/utils/defaulVeriables';
import DetailPico from '@/views/admin/DetailPico/DetailPico';
import { i18n } from '@/i18n';

const columnsSuperAdmin = [
  {
    title: i18n.t('picos.pico_id'),
    dataIndex: 'pico_id',
    key: 'pico_id',
    sorter: true,
    scopedSlots: { customRender: 'pico_id' },
  },
  {
    title: i18n.t('picos.alias'),
    dataIndex: 'alias',
    key: 'alias',
    sorter: true,
    scopedSlots: { customRender: 'alias' },
  },
  {
    title: i18n.t('organizations.organization_name'),
    dataIndex: 'organization',
    key: 'organization_name',
    sorter: true,
    customRender: (organization) => {
      return organization ? organization.name : ''
    }
  },
  {
    title: i18n.t('picos.storage_base_id'),
    dataIndex: 'base_id',
    key: 'base_id',
    sorter: true,
    scopedSlots: { customRender: 'base_id' }
  },
  {
    title: i18n.t('basics.status_title'),
    dataIndex: 'status',
    key: 'status',
    width: '15%',
    sorter: true,
    scopedSlots: { customRender: 'status' }
  },
  {
    dataIndex: 'action',
    key: 'action',
    width: '15%',
    scopedSlots: { customRender: 'action' }
  }
];
const columnsAdmin = [
  {
    title: i18n.t('picos.pico_id'),
    dataIndex: 'pico_id',
    key: 'pico_id',
    width: '20%',
    sorter: true,
    scopedSlots: { customRender: 'pico_id' },
  },
  {
    title: i18n.t('picos.alias'),
    dataIndex: 'alias',
    key: 'alias',
    sorter: true,
    scopedSlots: { customRender: 'alias' },
  },
  {
    title: i18n.t('picos.storage_base_id'),
    dataIndex: 'base_id',
    key: 'base_id',
    sorter: true,
    scopedSlots: { customRender: 'base_id' }
  },
  {
    title: i18n.t('basics.status_title'),
    dataIndex: 'status',
    key: 'status',
    sorter: true,
    scopedSlots: { customRender: 'status' }
  },
  {
    dataIndex: 'action',
    key: 'action',
    width: '15%',
    scopedSlots: { customRender: 'action' }
  }
];

export default {
  name: 'PicosList',
  components: {
    TableSizeChanger,
    ListTitle,
    ListHeading,
    Search,
    ListFilter,
    Pagination,
    DetailPico
  },
  data() {
    return {
      formatDate,
      loading: false,
      searchPlaceholder: this.$t('picos.search_placeholder'),
      columnsSuperAdmin,
      columnsAdmin,
      filterCheckboxes: defaulVariablesPicos,
      visibleModal: false,
      endpoint: 'GetPicosList',
      emptyText: this.$t('picos.empty_text')
    }
  },
  props: {
    placeholderText: {
      type: String,
      required: false,
      default: ''
    },
    defaultPageSize: {
      type: Number,
      required: false,
      default: null
    },
    defaultPageNumber: {
      type: Number,
      required: false,
      default: null
    },
    paginationData: {
      type: Object,
      required: false,
      default: null
    },
    dataSource: {
      type: Array,
      required: false,
      default: null
    },
    organizations: {
      type: Array,
      required: false,
      default: null
    },
    checkboxes: {
      type: Array,
      required: false,
      default: null
    },
    columnsData: {
      type: Array,
      required: false,
      default: null
    },
    emptyTableText: {
      type: String,
      required: false,
      default: i18n.t('bases.no_data')
    }
  },
  mounted() {
    this.fetchData();
    this.$store.dispatch('organizations/GetOrganizationDropdownList');
  },
  computed: {
    picosList() {
      return this.$store.getters['picos/getPicosTableData'];
    },
    getRole() {
      return parseInt(this.$store.getters['getRole'])
    },
    pageSize() {
      return this.$store.getters['picos/getPageSize'];
    },
    pageNumber() {
      return this.$store.getters['picos/getPageNumber'];
    },
    filters() {
      return this.$store.getters['picos/getFilters'];
    },
    pagination() {
      const { meta } = this.$store.getters['picos/getPicosList']
      return meta ? meta : {}
    },
    organizationsDropdownList() {
      return this.$store.getters['organizations/getOrganizations']
    },
    computedRouteName() {
      return this.$route.name;
    }
  },
  watch: {
    computedRouteName: {
      immediate: true,
      handler(name) {
        if (name === 'PicosList') {
          this.$echo.private(`picos-list`).listen('.status_updated', () => {
            this.fetchData(({page: this.pageNumber, ...this.filters}));
          }).error(err => {
            console.log('error');
            console.log({err});
          });

          // this.sendDebouncedRequest = _.debounce(this.sendDebouncedRequest, 3000);
          // this.$echo.private(`picos-list`).listen('.status_updated', () => {
          //   this.sendDebouncedRequest();
          // });
        } else {
          this.$echo.leave('picos-list');
        }
      }
    }
  },
  methods: {
    // sendDebouncedRequest() {
    //   console.log('sendDebouncedRequest');
    //   this.fetchData(({page: this.pageNumber, ...this.filters}));
    // },
    fetchData(value) {
      this.loading = true;
      this.$store.dispatch('picos/GetPicosList', value ? value : {})
          .then(()=> { this.loading = false })
    },
    setPageSize(value) {
      this.$store.commit('picos/SET_PAGE_SIZE', value)
      this.fetchData()
    },
    setPageNumber(value) {
      this.fetchData({page: value})
    },
    filterList(params) {
      this.$store.commit('picos/SET_FILTERS', params);
      this.fetchData({params})
    },
    handleTableChange(pagination, filters, sorter) {
      var sortOrderName = sorter.order === 'ascend' ? 'asc' : 'desc',
          sortParams = {sortField: sorter.columnKey, sortOrder: sortOrderName};

      sortParams = sorter.order ? sortParams : {}
      this.$store.commit('picos/SET_SORT', sortParams)
      this.fetchData(sortParams)
    },
    searchInList: _.debounce(function (value) {
      this.$store.commit('picos/SET_SEARCH_VALUE', value);
      if(value.trim().length >= 3) {
        this.fetchData({ search: value })
      } else if(value === '') {
        this.fetchData()
      }
    }, 700),
    setActiveModal(value, itemID) {
      this.visibleModal = value
      if (itemID) {
        this.$store.dispatch('detailPico/GetPico', itemID)
      }
    }
  }
}
</script>