<template>
  <main-modal
      :active="active"
      @onClose="onClose"
      :bodyPadding="'0 60px'">
    <template v-slot:modalHeader>
      <a-button
          @click="onClose()"
          class="back-btn button-without-animation">
        <i class="anticon"><arrow/></i>
        {{ $t('picos.picos_list') }}</a-button>
    </template>
    <template v-slot:modalBody>
      <pico-form
          @onClose="onClose"/>
    </template>
  </main-modal>
</template>

<script>
import MainModal from '@/components/MainModal/MainModal'
import arrow from '@/assets/icons/back.svg?inline'
import PicoForm from '@/views/admin/PicoForm/PicoForm'

export default {
  name: 'DetailPico',
  props: ['active'],
  components: {
    arrow,
    MainModal,
    PicoForm
  },
  methods: {
    onClose() {
      this.$store.commit('detailPico/SET_PICO_DETAIL', {})
      this.$emit('closeModal', false)
      this.$emit('updateList')
    }
  }
}
</script>


